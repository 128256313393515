<template>
  <div>
    <div class="row mb-2 align-items-center">
      <div class="align-items-center col-12">
        <i class="fa fa-arrow-left fa-lg text-dark mr-2" @click="$router.go(-1)"></i>
        <span class="font-weight-bold h3">{{ getTitle }}</span>
      </div>
    </div>
    <div v-if="isLoading">
      <div class="text-center">{{ $t('commons.data_loading') }}...</div>
    </div>
    <div class="table-responsive" v-else>
      <table class="table table-striped table-bordered collapsed" style="width: 100%" ref="kt_datatable">
        <thead>
          <tr>
            <th scope="col">#</th>
            <th scope="col">{{ $t('commons.currency_code') }}</th>
            <th scope="col">{{ $t('commons.quantity') }}</th>
            <!-- <th scope="col">{{ $t('miracle_deal.fee') }}</th> -->
            <th scope="col">{{ $t('commons.duration') }}</th>
            <!-- <th scope="col">{{ $t('miracle_deal.customer_ratio') }}</th> -->
            <th scope="col">{{ $t('miracle_deal.countdown') }}</th>
            <!-- <th scope="col">{{ $t('commons.status') }}</th> -->
          </tr>
        </thead>
        <tbody>
          <tr v-for="(item, index) in getList" :key="'marketOrder-' + index">
            <th class="font-weight-bolder">{{ item.id }}</th>
            <td>
              <span style="width: 250px">
                <div class="d-flex align-items-center">
                  <div class="symbol symbol-40 flex-shrink-0">
                    <div class="media align-items-center">
                      <img :src="`${iconUrl}${item.currency_code.toLowerCase()}.png`" height="30" alt="" class="cc mr-2 font-large-2 warning d-none d-md-block" />
                    </div>
                  </div>
                  <div class="ml-3">
                    <div class="text-dark-75 font-weight-bold line-height-sm">{{ item.currency_code }}</div>
                  </div>
                </div>
              </span>
            </td>
            <td>{{ item.quantity }}</td>
            <!-- <td class="text-muted">
            <div class="row justify-content-center">
              <span class="mr-sm-1">%</span>
              <span>{{ item.fee }}</span>
            </div>
          </td> -->
            <td class="text-muted">
              <div class="row justify-content-center">
                <span class="mr-sm-1">{{ item.duration }}</span>
                <span>{{ textDuration(item.duration_type) }}</span>
              </div>
            </td>
            <!-- <td class="text-muted">
            <div class="row justify-content-center">
              <span class="mr-sm-1">%</span>
              <span>{{ item.ratio }}</span>
            </div>
          </td> -->
            <td>
              <span style="width: 250px">
                <div class="d-flex align-items-center">
                  <div class="ml-2">
                    <div v-if="item.sign_date" class="text-primary font-weight-bold line-height-sm">{{ item.sign_date | dateFormat }}</div>
                  </div>
                </div>
              </span>
            </td>
            <!-- <td>
            <span :class="['label label-lg label-inline', getOrderStatusClassAndLabel(item.status).class]">
              {{ getOrderStatusClassAndLabel(item.status).label }}
            </span>
          </td> -->
            <td>
              <div class="flex-column d-flex">
                <button v-if="user.id !== item.user_id && item.status === 1" @click="transfer(item.id)" class="btn btn-icon btn-sm btn-gradient-success mb-1" :title="isStriker ? $t('miracle_deal.keeper_new') : $t('miracle_deal.striker_new')">
                  <i class="la la-plus text-white"></i>
                  {{ isStriker ? $t('miracle_deal.keeper_new') : $t('miracle_deal.striker_new') }}
                </button>
                <button type="button" @click="showDetailsModal(item)" class="btn btn-icon btn-gradient-primary" :title="$t('commons.details')">
                  <i class="la la-info text-white"></i>
                  <span class="text-uppercase">
                    {{ $t('commons.details') }}
                  </span>
                </button>
              </div>
            </td>
          </tr>
        </tbody>
      </table>
    </div>
    <DealModal v-if="itemDetailsModal" ref="marketSKItemDetailsModal" :title="`#${selectedModalItem.id} - ${$t('commons.details')}`">
      <template v-slot:body>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('miracle_deal.fee') }}</p>
          <h6 class="mb-0">% {{ selectedModalItem.fee }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('miracle_deal.customer_ratio') }}</p>
          <h6 class="mb-0">% {{ selectedModalItem.ratio }}</h6>
        </div>
        <div class="d-flex justify-content-between mt-1">
          <p class="mb-0">{{ $t('commons.status') }}</p>
          <h6 class="mb-0">
            <span :class="['label label-lg label-inline', getOrderStatusClassAndLabel(selectedModalItem.status).class]">
              {{ getOrderStatusClassAndLabel(selectedModalItem.status).label }}
            </span>
          </h6>
        </div>
      </template>
    </DealModal>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import dateFormat from '@/config/_date-format';
import Swal from 'sweetalert2';
import ListMixin from './list.mixin';
import DealModal from './DealModal.vue';

const cdnBaseUri = process.env.VUE_APP_OFINANS_CDN;
export default {
  name: 'MarketOrders',
  props: {
    orderType: {
      type: String,
      default: 'mdStrikerMarket'
    }
  },
  mixins: [ListMixin],
  data() {
    return {
      isLoading: true,
      iconUrl: `${cdnBaseUri}/images/currency/`,
      selectedModalItem: {}
    };
  },
  components: {
    DealModal
  },
  created() {
    const self = this;
    if (this.isStriker) {
      self.$store.dispatch('striker/GET_STRIKER').then(function () {
        self.isLoading = false;
      });
      return;
    }
    self.$store.dispatch('keeper/GET_KEEPER').then(function () {
      self.isLoading = false;
    });
  },
  computed: {
    ...mapState({
      strikerList: (state) => state.striker.strikerList,
      keeperList: (state) => state.keeper.keeperList,
      user: (state) => state.customer.user,
      itemDetailsModal: (state) => state.global.dealModal
    }),
    isStriker() {
      return this.orderType === 'mdStrikerMarket';
    },
    getTitle() {
      // return this.isStriker ? this.$t('miracle_deal.market_striker_orders') : this.$t('miracle_deal.market_keeper_orders');
      const skText = this.isStriker ? "Striker" : "Keeper";
      return `${skText} - ${this.$t('miracle_deal.waiting_deals')}`;
    },
    getList() {
      return this.isStriker ? this.strikerList : this.keeperList;
    }
  },
  filters: {
    dateFormat
  },
  methods: {
    textDuration(id) {
      switch (id) {
        case 1:
          return 'D';
        case 2:
          return 'M';
        case 3:
          return 'Y';
        case 4:
        case 5:
        default:
          return 'D';
      }
    },
    transfer(id) {
      const keeperOrStriker = this.isStriker ? 'Keeper' : 'Striker';
      const title = this.$t('miracle_deal.create_new_order_from_market_order', { keeperOrStriker });
      Swal.fire({
        title: title,
        text: this.$t('commons.wont_be_able_to_revert'),
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: this.$t('commons.yes'),
        cancelButtonText: this.$t('commons.cancel')
      }).then((result) => {
        if (result.value) {
          this.isStriker ? this.$store.dispatch('striker/TRANSFER', id) : this.$store.dispatch('keeper/TRANSFER', id);
        }
      });
    },
    showDetailsModal(item) {
      this.selectedModalItem = item;
      this.$store.commit('global/SET_DEAL_MODAL', true);
    }
  }
};
</script>
<style scoped>
table,
thead,
tr,
tbody,
th,
td {
  text-align: center;
}

.table th,
td {
  text-align: center;
  vertical-align: middle;
}
</style>
